<template>
  <div class="plugins">
    <div class="blocks-container mb-3">
      <div v-for="application_settings in applications_settings" :key="application_settings.key" class="block-item" @click="addToSettings(application_settings)">
        <div class="logo-container" v-if="application_settings.icon">
          <img :src="application_settings.icon" alt="Logo" class="logo">
        </div>
        <div class="label-container">
          <span>{{ application_settings.label }}</span>
          <b-icon icon="plus" class="plus-icon"></b-icon>
        </div>
      </div>
    </div>     

    <div class="applications-wrapper">
      <div v-for="(application, index) in used_applications" :key="application.index" class="applications-item">
        <b-overlay
          :show="application['loading']"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-card class="bg-white rounded position-relative">
            <b-card-header>
              <div class="application-header">
                <p class="m-0">
                  <strong>{{ application.label }} </strong> 
                  <span>(Last Sync Date: {{ application.last_sync_date }})</span>
                </p>
                <div class="application-actions">
                  <b-form class="sync-mode" @submit="syncApplication">
                    <b-form-radio-group checked="last" name="mode">
                      <b-form-radio value="last">Last</b-form-radio>
                      <b-form-radio value="full">Full</b-form-radio>
                    </b-form-radio-group>
                    <input type="hidden" name="frontIndex" :value="index">
                    <input type="hidden" name="applicationId" :value="application._id.toString()">
                    <b-button
                      type="submit"
                      :disabled="['process', 'waiting'].includes(application.sync_status)"
                      :variant="application.sync_status === 'failed' ? 'danger' : 'primary'"
                      style="top: 10px; right: 10px"
                      :class="[['process', 'waiting'].includes(application.sync_status) ? 'disabled' : '']"
                      v-b-tooltip="{ title: replaceErrorMessage(application.sync_error), customClass: 'error-tooltip-lead-center', placement: 'left', variant: 'danger', boundary: 'viewport', html: true }">
                      {{ application.sync_status === 'process' ? 'Sync in process...' : application.sync_status === 'failed' ? 'Error' : application.sync_status === 'waiting' ? 'Waiting sync' : 'Sync' }}
                    </b-button>
                  </b-form>
                  <b-button
                    variant="primary"
                    style="top: 10px; right: 10px"
                    @click="saveApplication(application, index)">
                    <b-icon icon="save"></b-icon>
                  </b-button>
                  <b-button
                    variant="danger"
                    style="top: 10px; right: 10px"
                    @click="deleteApplication(application, index)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </div>
              </div>
            </b-card-header>
            <div class="application-settings">
              <div v-for="(setting, index) in application.settings" :key="index">
                <b-row v-if="setting.type === 'Boolean'">
                  <b-col class="d-flex align-items-center" sm="2">
                    <label class="mb-0" :for="`input-${application.key}-${index}`">{{ setting.label }}:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-checkbox
                      :id="`input-${application.key}-${index}`"
                      class="active-checkbox"
                      name="check-button"
                      v-model="application.settings[index].value"
                      switch>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row v-if="setting.type === 'String'">
                  <b-col class="d-flex align-items-center" sm="2">
                    <label class="mb-0" :for="`input-${application.key}-${index}`">{{ setting.label }}:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-input
                      :id="`input-${application.key}-${index}`"
                      style="border: 1px solid grey"
                      class="p-2"
                      v-model="application.settings[index].value"
                      type="text">
                    </b-form-input>
                  </b-col>
                </b-row>
                <b-row v-if="setting.type === 'Radio'">
                  <b-col class="d-flex align-items-center" sm="2">
                    <label class="mb-0" :for="`input-${application.key}-${index}`">{{ setting.label }}:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-radio-group v-model="application.settings[index].value" name="radio-group">
                      <b-form-radio v-for="option in application.settings[index].options" :key="option" :value="option">
                        {{ option }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <b-row v-if="setting.type === 'Checkbox'">
                  <b-col class="d-flex align-items-center" sm="2">
                    <label class="mb-0" :for="`input-${application.key}-${index}`">{{ setting.label }}:</label>
                  </b-col>
                  <b-col sm="10">
                    <b-form-checkbox-group v-model="application.settings[index].value" name="checkbox-group">
                      <b-form-checkbox v-for="option in application.settings[index].options" :key="option" :value="option">
                        {{ option }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>
                </b-row>
                <b-row class="pt-1" v-if="setting.description">
                  <b-col class="d-flex align-items-center" sm="2"></b-col>
                  <b-col sm="10"><div style="font-size: 13px;">{{ setting.description }}</div></b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import { showSnackbar } from '@/services'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'lead-center-applications-new',
  data() {
    return {
      applications_settings: [],
      used_applications: [],
      intervalId: null,
      codeMirrorOptions: {
        lineWrapping: true
      }
    }
  },
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  watch: {
    selectedPlatform() {
      this.init()
    }
  },
  props: ['type'],
  async mounted() {
    this.init()
    this.intervalId = setInterval(this.updateStatuses, 5000)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform'
    ]),
    ...mapMutations(['setViewLoader']),
    updateStatuses() {
      (async () => {
        const platform_applications = await MtApi.getPlatform(this.selectedPlatform._id, { select: 'new_applications' })
        if (platform_applications.new_applications) {
          const filteredApplications = platform_applications.new_applications.map((na, index) => ({
            originalIndex: index,
            application: na
          })).filter(({ application }) => application.type === `${this.type}_extractor`);
          for (const fa of filteredApplications) {
            const existApplicationIndex = this.used_applications.findIndex((na) => na.key === fa.application.key && na.index === fa.originalIndex)
            if (existApplicationIndex !== -1) {
              this.$set(this.used_applications, existApplicationIndex, {
                ...this.used_applications[existApplicationIndex],
                last_sync_date: fa.application?.last_sync_date,
                sync_start: fa.application?.sync_start,
                sync_status: fa.application?.sync_status,
                sync_error: fa.application?.sync_error
              });
            }
          }
        }
      })()
    },
    init() {
      (async () => {
        const [applications_settings, platform_applications] = await Promise.all([
          MtApi.getModules(),
          MtApi.getPlatform(this.selectedPlatform._id, { select: 'new_applications' })
        ])
        this.applications_settings = applications_settings.filter((as) => as.type === `${this.type}-extractor`)
        if (platform_applications.new_applications) {
          const filteredApplications = platform_applications.new_applications.map((na, index) => ({
            originalIndex: index,
            application: na
          })).filter(({ application }) => application.type === `${this.type}_extractor` && this.applications_settings.map((as) => as.key).includes(application.key));
          this.used_applications = [
            ...filteredApplications.map((fa) => {
              let application_setting = { ...applications_settings.find((ap) => ap.key === fa.application.key) }
              application_setting.settings = application_setting.settings.map((as) => {
                return {
                  ...as,
                  value: fa.application.settings[as.key] || as.value
                }
              })
              return {
                _id: fa.application._id,
                key: fa.application.key,
                index: fa.originalIndex,
                ...application_setting,
                last_sync_date: fa.application.last_sync_date,
                sync_start: fa.application.sync_start,
                sync_status: fa.application.sync_status,
                sync_error: fa.application.sync_error
              }
            })
          ]
          this.used_applications.sort((a, b) => {
            return a.index - b.index;
          });
        } else {
          this.used_applications = []
        }
      })()
    },
    async addToSettings(application) {
      this.setViewLoader(true)
      const data = { key: application.key, index: application?.index, type: `${this.type}_extractor` }
      await MtApi.updatePlatform(this.selectedPlatform._id, {
        event: 'update_new_applications',
        field: {
          value: {
            data,
            settings: this.settingsMapper(application)
          }
        }
      })
      this.init()
      this.setViewLoader(false)
    },
    settingsMapper(application) {
      const settings = {}
      for (const setting of application.settings) {
        let value = setting.value || null
        if (setting.key === 'active' && !setting.value) {
          value = false
        }
        settings[setting.key] = value
      }
      return settings
    },
    applicationLoader(index, load = false) {
      this.used_applications[index]['loading'] = load
      this.used_applications = [
        ...this.used_applications
      ]
    },
    async syncApplication(event) {
      event.preventDefault()
      const form = new FormData(event.target);
      const mode = form.get('mode');
      const frontIndex = parseInt(form.get('frontIndex'), 10);
      const applicationId = form.get('applicationId');
      this.applicationLoader(frontIndex, true);
      const response = await MtApi.syncApplication({
        platform: this.selectedPlatform._id,
        _id: applicationId,
        mode
      });
      if (response?.message) {
        showSnackbar({
          text: response.message,
          mode: 'danger'
        })
      }
      await this.updateStatuses();
      this.applicationLoader(frontIndex, false);
    },
    async saveApplication(application, index) {
      this.applicationLoader(index, true)
      const data = { key: application.key, index: application?.index, type: `${this.type}_extractor` }
      await MtApi.updatePlatform(this.selectedPlatform._id, {
        event: 'update_new_applications',
        field: {
          value: {
            data,
            settings: this.settingsMapper(application)
          }
        }
      })
      this.init()
      showSnackbar({
        text: 'Saved!',
        mode: 'success'
      })
      this.applicationLoader(index, false)
    },
    async deleteApplication(application, index) {
      this.setViewLoader(true)
      this.applicationLoader(index, true)
      const data = { key: application.key, index: application?.index, type: `${this.type}_extractor` }
      await MtApi.updatePlatform(this.selectedPlatform._id, {
        event: 'delete_new_applications',
        field: {
          value: {
            data
          }
        }
      })
      this.init()
      showSnackbar({
        text: 'Deleted!',
        mode: 'success'
      })
      this.applicationLoader(index, false)
      this.setViewLoader(false)
    },
    isChecked(option, value) {
      return value.includes(option);
    },
    handleCheckboxChange(option, settingIndex, application) {
      // let values = application.settings[settingIndex].value
      // ? application.settings[settingIndex].value.split(',')
      // : [];

      // if (values.includes(option)) {
      //     values = values.filter(v => v !== option); // Убираем значение, если оно уже выбрано
      // } else {
      //     values.push(option); // Добавляем значение, если оно не выбрано
      // }

      // // Используем this.$set, чтобы убедиться, что Vue отслеживает изменения
      // this.$set(application.settings[settingIndex], 'value', values.join(','));
    },
    replaceErrorMessage(message) {
      return SimpleHelper.replaceBase64WithImgTag(message);
    }
  }
}
</script>

<style lang="scss" scoped>
.application-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  .application-actions {
    display: flex;
    gap: 10px;
  }
}
.applications-item {
  &:not(:last-child) {
    padding-bottom: 15px;
  }
  .card-body {
    padding: 0;
  }
  .application-settings {
    padding: 1.25em;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
}
.actions {
  margin-top: 20px;
}

.blocks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.block-item {
  flex: 1 1 calc(25% - 20px);
  min-width: 200px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 0;
  background-color: #fff;
  transition: box-shadow 0.3s, background-color 0.3s;
  cursor: pointer;
  height: 60px;
}

.block-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #e9f5ff;
}

.logo-container {
  margin-right: 10px;
}

.logo {
  width: 50px;
  max-width: 50px;
  height: auto;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.plus-icon {
  display: none;
  margin-left: 10px;
  color: #7887fe;
  transition: color 0.3s;
  font-size: 1.5em;
}

.block-item:hover .plus-icon {
  display: inline;
}

.tabs-page .card {
  border-radius: 0 !important;
}

::v-deep {
  .custom-control-label {
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
}

.sync-mode {
  display: flex;
  align-items: center;
}

</style>
